<template>
  <mds-layout-grid>
    <mds-row 
      align-horizontal="center" 
      align-vertical="center" 
      class="dv-key-feature-item-row"
    >
      <mds-col class="dv-key-feature-item-col" :cols="12">
        <div class="dv-key-feature-item-row-icon">
          <img class="dv-key-feature-item-row-icon-img" :src="imagePath()"/>
        </div>
      </mds-col>
    </mds-row>
    <mds-row 
      align-horizontal="center" 
      align-vertical="center" 
      class="dv-key-feature-item-row"
    >
      <mds-col class="dv-key-feature-item-col" :cols="12">
        <div v-html="description" class="dv-key-feature-item-row-desc"/>
      </mds-col>
    </mds-row>
  </mds-layout-grid>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';

export default {
  name: 'KeyFeaturesItem',
  components: {
    MdsRow,
    MdsCol,
    MdsLayoutGrid,
  },
  methods: {
    imagePath() {
      return require('../../assets/' + this.imageFileName);
    },
  },
  props: {
    description: {
      type: String,
      default: '',
      required: true,
    },
    imageFileName: {
      type: String,
      default: '',
      required: false,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dv-key-feature-item {
  &-row {
    margin-top: $mds-space-4-x;
    margin-bottom: $mds-space-4-x;
    &-desc {
      @include mds-level-5-heading();
    }
    &-icon {
      background: $mds-background-color-white;
      width: 140px;
      height: 140px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      &-img {
        padding: $mds-space-1-x;
        width: 70%;
        height: 70%;
      }
    }
  }
  &-col {
    text-align: center;
    justify-content: center;
    display: flex;
  }
}
</style>
