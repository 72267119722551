<template>
  <div class="mcia-outer-conatiner">
    <div class="mcia-inner-container">
      <div>
        <div class="product-key-features-title">{{ title }}</div>
        <mds-layout-grid class="product-key-features">
          <mds-row align-horizontal="center" align-vertical="center">
            <mds-col
              v-for="(item, index) in featuresList"
              align-vertical="center"
              :key="`key-feature-${index}`"
              :cols="12"
              :cols-at-m="4"
            >
              <Key-features-item 
                :key="`key-feature-item-${index}`" 
                :description="item" 
                :imageFileName="`key-feature-${index}.svg`"
              />
            </mds-col>
          </mds-row>
        </mds-layout-grid>
      </div>
    </div>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import KeyFeaturesItem from '@/components/Product/KeyFeaturesItem.vue';
export default {
  name: 'KeyFeatures',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    KeyFeaturesItem,
  },
  data() {
    return {
      title: 'Key Features',
    };
  },
  props: {
    featuresList: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.product-key-features {
  height: auto;
  min-height: 200px;
  margin: 0 auto;
  &-title {
    text-align: center;
    @include mds-level-3-heading($bold: true);
    color: $mds-text-color-secondary;
    padding: $mds-space-2-x 0;
  }
}
.mcia-outer-conatiner {
  background-color: $mds-background-color-light-gray;
  .mcia-inner-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: $mds-space-4-x;
    padding-bottom: $mds-space-4-x;
  }
}
</style>
