import { render, staticRenderFns } from "./KeyFeatures.vue?vue&type=template&id=75448d58&scoped=true&"
import script from "./KeyFeatures.vue?vue&type=script&lang=js&"
export * from "./KeyFeatures.vue?vue&type=script&lang=js&"
import style0 from "./KeyFeatures.vue?vue&type=style&index=0&id=75448d58&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75448d58",
  null
  
)

export default component.exports